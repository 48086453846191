import type { PageLoad } from './$types';
import {
  defaultLocale,
  localized,
} from './(landing-page)/[lang=locale]/localization';

export const prerender = true;

export const load = (() => ({
  title: localized[defaultLocale].pomeTitle,
})) satisfies PageLoad;
